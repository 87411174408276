/**
 * useLabel hook
 * @returns {object}
 */
export const useLabel = () => {
    // todo: get from api

    // keep case
    return {
        // noStock: 'Není skladem',
        LBL_BAD_DATA: 'Špatná data',
        LBL_EMAIL_EXISTS: 'Email již existuje',
        LBL_EMAIL_SEND_FAILED: 'Nastala chyba EMF01',
        LBL_FORBIDDEN: 'Neoprávněná akce',
        LBL_FORBIDDEN_PASSWD_CHANGE: 'Chybné původní heslo',
        LBL_NOT_FONUD: 'Nenalezeno',
        LBL_NO_EMAIL_TPL: 'Nastala chyba ENT01',
        LBL_REQUEST_EXISTS: 'Žádost s tímto emaile již existuje.',
        accept: 'Přijmout',
        accepted: 'Přijato',
        accessToB2B: 'Přístup do B2B sekce',
        account: 'Účet',
        activeFilters: 'Aktivní filtry',
        addToCart: 'Přidat do košíku',
        address: 'adresa',
        alternativeProducts: 'Alternativní produkty',
        back: 'Zpět',
        backToHome: 'Zpět na úvodní stránku',
        backToLogin: 'Zpět k autorizaci',
        backWheel: '',
        billingAddress: 'Fakturační adresa',
        billingCompanyByUserBranch: 'Fakturační firma podle pobočky uživatele',
        brand: 'Značka',
        businessName: 'Firma',
        buyAgain: 'Znovu koupit',
        cancelFilters: 'Zrušit filtry', // Zrušit vybrané parametry
        fixQtyOrChangeDelivery: 'Prosím, upravte počty kusů nebo změňte způsob dodání.',
        cart: 'Nákupní košík',
        acceptTermsIsRequired: 'Je nutné přijmout obchodní podmínky',
        cartIsEmpty: 'Košík je prázdný',
        cartIsUpToDate: 'Košík aktualizován',
        cartNote: 'Poznámka k objednávce',
        categories: 'Kategorie',
        changePassword: 'Změnit heslo',
        city: 'Město',
        company: 'Název firmy',
        contactInfo: 'Kontaktní údaje',
        confirmOrder: 'Potvrdit objednávku',
        contact: 'kontakt',
        contactPerson: 'Kontaktní osoba',
        contactForm: 'Kontaktní formulář - poptávka / doporučení',
        continue: 'Pokračovat',
        currency: 'Kč', // fixme: not label
        delivery: 'dodání',
        deliveryAddress: 'Dodací adresa',
        doBindingOrder: 'Závazně objednat',
        doLogin: 'Přihlásit se',
        doOrder: 'Objednat',
        doPrint: 'Vytisknout',
        doSaveAddress: 'Uložit adresu pro příští objednávku',
        doSaveContact: 'Uložit kontakt pro příští objednávku',
        doSearch: 'Vyhledat',
        doSort: 'Seřadit',
        drop: 'Smazat',
        dropCart: 'Vyprázdnit košík',
        email: 'Email',
        empty: 'prázdný',
        errorCaught1: 'Omlouváme se',
        errorCaught2: 'nastala chyba',
        errorCaughtContent: 'Omlouváme se nastala chyba při zpracování akce. <br/>Zkuste to prosím znovu. Pokud problém přetrvává, <br/>neváhejte nás kontaktovat.',
        executionAddress: 'Adresa provedení',
        fillForm: 'Zaplnit formulář',
        filtering: 'Filtrování',
        firstName: 'Jméno',
        forgotPass: 'Zapomenuté heslo',
        frontWheel: '',
        headerSalesContact: 'Váš obchodní zástupce',
        headerSalesContactButton: 'Kontaktovat',
        helpAndSupport: 'Pomoc a podpora',
        hideFilters: 'Skrýt filtry',
        ico: 'IČO',
        installApp: 'Chcete nainstalovat aplikaci na plochu?',
        installBtn: 'Instalovat',
        installDate: 'termín montáže',
        installTire: 'Montáž pneu',
        internalNum: 'Interní číslo',
        invoices: 'Faktury',
        jobTitle: 'Pracovní pozice',
        label: '',
        lastName: 'Příjmení',
        lastSeen: 'Poslední prohlížené',
        limitedStock: 'omezené množství',
        loading: 'Načítání',
        login: 'Přihlášení',
        loginErr: 'Chyba autorizace',
        logout: 'Odhlásit se',
        manufacturer: 'Výrobce',
        manufacturerNum: 'Výrobní číslo',
        message: 'Zpráva',
        model: 'Model',
        myOrders: 'Moje objednávky',
        myAccount: 'Můj účet',
        newPass: 'Nové heslo',
        newPassword: 'Nové heslo',
        newPasswordRepeat: 'Nové heslo znovu',
        noAttachments: 'Žádné přílohy',
        noAttrsSelected: 'Nebyly nalezeny žádné atributy',
        noInvoices: 'Žádné faktury',
        noOrders: 'Žádné objednávky',
        noProducts: 'Žádné produkty',
        noResults: 'Žádné výsledky',
        noStock: 'není skladem',
        noVat: 'bez DPH',
        note: 'Poznámka',
        offerFillMachineRequest: 'Nemůžete najít požadované zboží? Zadejte poptávku na doručení!',
        oldPassword: 'Staré heslo',
        onSale: 'výprodej',
        order: 'Objednávka',
        orderAccount: 'Objednatel - firma',
        orderAdditionalInfoIsUploading: 'Nahrávání dodatečných informací o objednávce...',
        orderAgain: 'Znovu objednat',
        orderBranch: 'Objednatel - pobočka',
        orderContact: 'Kontakt',
        orderCreatePending: 'vytváření',
        orderCreatedBy: 'Vytvořil',
        orderCreatedDate: 'Vystaveno',
        orderEnteredDate: 'Vytvořeno',
        orderInfoIsUploading: 'Nahrávání informací o objednávce...',
        orderIsSent: 'Vaše objednávka byla odeslána',
        orderSendError: 'Nastala chyba při odesílání objednávky',
        orderSummary: 'Rekapitulace objednávky',
        orders: 'Objednávky',
        pageNotFound: 'Stránka nenalezena',
        pageNotFoundContent: 'Omlouváme se, ale stránka, kterou hledáte, <br/>nebyla nalezena.',
        parameters: 'Parametry',
        password: 'Heslo',
        passwordMustContainNumber: 'Heslo musí obsahovat alespoň jedno číslo',
        passwordMustContainUppercase: 'Heslo musí obsahovat alespoň jedno velké písmeno',
        passwordRepeat: 'Zopakujte heslo',
        passwordTooShort: 'Heslo je příliš krátké (min. 8 znaků)',
        passwordWasSet: 'Heslo bylo nastaveno',
        passwordsDoNotMatch: 'Hesla se neshodují',
        phone: 'Telefonní číslo',
        postalCode: 'PSČ',
        price: 'Cena',
        priceCurrentNoVat: 'Cena bez DPH',
        pricePrev: 'Původní cena',
        productCodeShort: 'Kat. č.',
        profile: 'Profil',
        purpose: 'Náprava',
        qty: 'ks', // fixme: not label
        recommendedProducts: 'Doporučené produkty',
        requestForProduct: 'Požadavek na produkt',
        requestTitle: 'Požádat o přístup do B2B sekce',
        requiredField: 'Vyplňte všechna povinná pole',
        resetPass: 'Reset hesla',
        resultsIsUpdated: 'Výsledky byly aktualizovány',
        sale: 'AKCE',
        save: 'Uložit',
        saveError: 'Nastala chyba při ukládání',
        saved: 'Uloženo',
        saving: 'Ukládání',
        searchProducts: 'Vyhledat produkty',
        searchResults: 'Výsledky vyhledávání',
        selectSavedAddress: 'Vybrat uloženou adresu',
        selectSavedContact: 'Vybrat uložený kontakt',
        selectWeek: 'Vybrat týden',
        selfPickup: 'Vlastní příjezd',
        sendRequest: 'Poslat požadavek',
        serviceOrderDate: 'Datum montáže',
        serviceOrderPrint: 'Servisní výkaz',
        serviceOrders: 'Servisní objednávky',
        serviceOrdersBtn: 'Servisní obj.',
        shippingAccount: 'Koncový zákazník - firma',
        shippingBranch: 'Koncový zákazník - pobočka',
        showCatalog: 'Zobrazit katalog',
        showLess: 'zobrazit méně',
        showMore: 'zobrazit více',
        showSelect: 'Podle stroje',
        sideBack: 'Zadní',
        sideFront: 'Přední',
        specify: 'Upřesnit',
        state: 'Stát',
        stepBack: 'Zpět',
        stock: 'skladem',
        street: 'Ulice a číslo popisné',
        terms1: 'Všeobecné obchodní podmínky',
        terms2: 'Zpracování osobních údajů',
        total: 'Celkem',
        totalNoVat: 'Mezisoučet (bez DPH)',
        totalVat: 'DPH (21%)',
        totalWithVat: 'Celková částka',
        tryAgain: 'Zkusit znovu',
        unknown: 'neznámý',
        upload: 'nahrát',
        uploadImage: 'nahrát obrazek',
        userProfileHeadline: 'Můj účet',
        userSettings: 'Nastavení uživatele',
        userSettingsShort: 'Nastavení',
        welcomeWish: 'Přejeme příjemný nákup v našem B2B eshopu.',
        whole: '',
        withVat: 's DPH',
    };
};
