import {api} from '@/api';

import {useContext, useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import toast from 'react-hot-toast';
import {useQueryClient} from '@tanstack/react-query';

import {useLabel} from '@/hooks/export';
import {
    BigTitle,
    CartList,
    CartNote,
    CartTotal,
    CartDelivery,
    CartContactForm,
    CartContactSummary,
    CatalogInfo,
    Footer,
    Header,
} from '@components/export';

import appContext from '@/appContext';

export const CartPage = (props: {
    step: 'cart' | 'contact'| 'summary',
}) => {
    const label = useLabel();

    const navigate = useNavigate();
    const queryClient = useQueryClient();

    const cartContext = useContext(appContext).cartContext;
    const {currentUser} = useContext(appContext).userContext;
    const {contactForm, setContactForm, productOptionsImages} = useContext(appContext).cartContext;

    const [showErrors, setShowErrors] = useState(false);
    const [termsAccepted, setTermsAccepted] = useState(false);
    const [productOptionsImagesBase64, setProductOptionsImagesBase64] = useState({});

    const ignoreFields = ['doSaveAddress', 'doSaveContact', 'info', 'info2'];

    const toastId = 'cart-submit';

    const flags = {
        cartHaveProducts:
            cartContext.cart &&
            cartContext.cart.list &&
            Object.entries(cartContext.cart.list).length,
    };

    const handle = {
        dropCart: () => {
            return cartContext.clean()
                .then(() => {
                    queryClient.invalidateQueries({queryKey: ['cart']});
                });
        },
        sendOrder: () => {
            toast.dismiss();
            toast.loading(label.orderInfoIsUploading, {id: toastId, duration: 60000});

            return cartContext.submit()
                .then((response) => {
                    if (!response.status || !response.data.orderId) {
                        throw label.orderSendError;
                    }

                    return response.data.orderId;
                })
                .then((orderId: string) => {
                    toast.loading(label.orderAdditionalInfoIsUploading, {id: toastId, duration: 60000});

                    return handle.sendAdditionalInfo(orderId);
                })
                .then((response) => {
                    if (!response.status) {
                        throw label.orderSendError;
                    } else {
                        toast.success(label.orderIsSent, {id: toastId});
                    }

                    return response.status;
                })
                .then(() => {
                    return handle.dropCart();
                })
                .then(() => {
                    navigate('/catalog');
                })
                .catch((error) => {
                    toast.error(error, {id: toastId});
                });
        },
        sendAdditionalInfo: (orderId: string) => {
            const composeAdditinalInfo = {};

            if (cartContext.cart) {
                Object
                    .entries(cartContext.cart.list)
                    .map(([productId, productData]) => {
                        composeAdditinalInfo[productId] = productData.additionalInfo;

                        if (productOptionsImages[productId]) {
                            composeAdditinalInfo[productId].images = productOptionsImagesBase64[productId];
                        }
                    });
            }

            return api.cart.sendAdditionalInfo(orderId, composeAdditinalInfo);
        },
        saveAddress: () => {
            api.user.saveAddress({
                city: contactForm.execCity,
                account: contactForm.execCompany,
                postal_code: contactForm.execPostalCode,
                street: contactForm.execStreet,
            })
                .then(() => {
                    queryClient.invalidateQueries({queryKey: ['savedAddresses']});
                });
        },
        saveContact: () => {
            api.user.saveContact({
                first_name: contactForm.contactFirstName,
                last_name: contactForm.contactLastName,
                email: contactForm.contactEmail,
                phone: contactForm.contactPhone,
            })
                .then(() => {
                    queryClient.invalidateQueries({queryKey: ['savedContacts']});
                });
        },
    };

    useEffect(() => {
        if (!flags.cartHaveProducts) {
            toast(label.cartIsEmpty);

            if (props.step !== 'cart') {
                navigate('/catalog');
            }
        }
    }, [cartContext.cart]);

    useEffect(() => {
        if (currentUser) {
            contactForm['invoiceCity'] = currentUser.invoiceCity;
            contactForm['invoiceCompany'] = currentUser.invoiceCompany;
            contactForm['invoicePostalCode'] = currentUser.invoicePostalCode;
            contactForm['invoiceStreet'] = currentUser.invoiceStreet;

            contactForm['execCity'] = currentUser.execCity;
            contactForm['execCompany'] = currentUser.execCompany;
            contactForm['execPostalCode'] = currentUser.execPostalCode;
            contactForm['execStreet'] = currentUser.execStreet;

            contactForm['contactEmail'] = currentUser.userName;
            contactForm['contactFirstName'] = currentUser.firstName;
            contactForm['contactLastName'] = currentUser.lastName;
            contactForm['contactPhone'] = currentUser.phone;

            setContactForm({...contactForm});
        }
    }, [currentUser]);

    useEffect(() => {
        window.scrollTo(0, 0);

        setContactForm({
            ...contactForm,
            doSaveAddress: false,
            doSaveContact: false,
        });
    }, [props.step]);

    useEffect(() => {
        const convertedImages = {};

        Object.entries(productOptionsImages)
            .map(([productId, attachments] : [string, machineRequestAttachment[]]) => {
                attachments.map((attachment) => {
                    convertedImages[productId] = [];

                    fileToBase64(attachment.file)
                        .then((newFile) => {
                            convertedImages[productId].push({
                                ...attachment,
                                file: newFile,
                            });
                        });
                });
            });

        setProductOptionsImagesBase64(convertedImages);
    }, [productOptionsImages]);

    // todo? split cart steps to single components
    // todo: refactor classes to class map

    return <>
        <Header/>

        <div className="page-content cart-page">
            {
                props.step === 'cart' ?
                    <>
                        <div className="cart-page-list container">
                            <BigTitle title={label.cart} dark={true} className={'cart-page-list__title'}/>
                            <CartList />
                        </div>

                        {
                            flags.cartHaveProducts && cartContext.cart ? // fixme: this check is only because tsc
                                <>
                                    <div className="cart-page-delivery container">
                                        <BigTitle
                                            title={label.delivery}
                                            dark={true}
                                            className={'cart-page-delivery__title'}
                                        />
                                        <CartDelivery/>
                                    </div>
                                    <div className="cart-page-list container">
                                        <CartNote initValue={cartContext.cart.note}/>
                                    </div>
                                    <div className="cart-page-total container">
                                        <CartTotal
                                            hideTerms
                                            buttons={{
                                                backward: {
                                                    label: label.dropCart,
                                                    onClick: () => handle.dropCart(),
                                                },
                                                forward: {
                                                    label: label.continue,
                                                    onClick: () => navigate('/cart/contact'),
                                                    tooltipMessage: label.fixQtyOrChangeDelivery,
                                                },
                                            }}
                                            blockForward={!cartContext.cart.flags.isOrderAllowed}
                                        />
                                    </div>
                                </> : <></>
                        }
                    </> : <></>
            }
            {
                props.step === 'contact' ?
                    <>
                        <div className="cart-page-list container">
                            <BigTitle title={label.contactInfo} dark={true} className={'cart-page-list__title'}/>
                            <CartContactForm
                                onChange={(data: {name: string, value: string|boolean}) => {
                                    contactForm[data.name] = data.value;
                                    setContactForm({...contactForm});
                                }}
                                fields={contactForm}
                                showErrors={showErrors}
                            />
                        </div>

                        {
                            flags.cartHaveProducts ?
                                <div className="cart-page-total container">
                                    <CartTotal
                                        hidePrice
                                        hideTerms
                                        buttons={{
                                            backward: {label: label.stepBack, onClick: () => navigate('/cart')},
                                            forward: {label: label.continue, onClick: () => {
                                                // fixme? extract body to separate callback
                                                // handle acceptContact

                                                const emptyFields = Object.entries(contactForm).filter((entry) => {
                                                    if (ignoreFields.filter((field) => entry[0] === field).length) {
                                                        return false;
                                                    }

                                                    return !entry[1];
                                                });

                                                setShowErrors(!!emptyFields.length);

                                                if (!emptyFields.length) {
                                                    if (contactForm.doSaveAddress) {
                                                        handle.saveAddress();
                                                    }

                                                    if (contactForm.doSaveContact) {
                                                        handle.saveContact();
                                                    }

                                                    navigate('/cart/summary');
                                                }
                                            }},
                                        }}
                                    />
                                </div> : <></>
                        }
                    </> : <></>
            }
            {
                props.step === 'summary' && cartContext.cart ?
                    <>
                        <div className="cart-page-list container">
                            <BigTitle title={label.orderSummary} dark={true} className={'cart-page-list__title'}/>
                            <CartContactSummary fields={contactForm}/>

                        </div>
                        <div className="cart-page-list container">
                            <CartList readonly/>
                        </div>

                        <div className="cart-page-list container">
                            <CartDelivery readonly/>
                        </div>

                        {/* cart note container */}
                        <div className="cart-page-list container">
                            <CartNote initValue={cartContext.cart.note}/>
                        </div>

                        {
                            flags.cartHaveProducts ?
                                <div className="cart-page-total container">
                                    <CartTotal
                                        buttons={{
                                            backward: {label: label.stepBack, onClick: () => navigate('/cart/contact')},
                                            forward: {label: label.doBindingOrder, onClick: () => handle.sendOrder(), tooltipMessage: label.acceptTermsIsRequired},
                                        }}
                                        onChangeTerms={(accepted) => setTermsAccepted(accepted)}
                                        blockForward={!termsAccepted}
                                    />
                                </div> : <></>
                        }
                    </> : <></>
            }

            <CatalogInfo/>
        </div>

        <Footer/>
    </>;
};

// todo: move to utils
const fileToBase64 = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result as string);
        reader.onerror = (error) => reject(error);
    });
};
