import utils from 'utils';
import {useContext, useState, useEffect} from 'react';
import {useNavigate} from 'react-router-dom';

import {useLabel} from '@/hooks/export';
import {BigTitle, UiButton, UiCheckbox} from '@components/export';

import Tooltip from '@mui/joy/Tooltip';

import appContext from '@/appContext';

export const CartTotal = (props: {
    buttons: {
        backward: {label: string, onClick: () => void, tooltipMessage?: string},
        forward: {label: string, onClick: () => void, tooltipMessage?: string}
    }
    hidePrice?: boolean,
    hideTerms?: boolean,
    blockForward?: boolean,
    onChangeTerms?: (accepted) => void,
}) => {
    const label = useLabel();
    const navigate = useNavigate();

    const cartContext = useContext(appContext).cartContext;

    const [acceptedTerms, setAcceptedTerms] = useState({
        term1: false,
        term2: false,
    });

    const handle = {
        clickTerm1(e) {
            e.preventDefault();
            navigate('/wiki/vseobecne-obchodni-podminky');
        },
        clickTerm2(e) {
            e.preventDefault();
            navigate('/wiki/zpracovani-osobnich-udaju');
        },
    };

    useEffect(() => {
        if (props.onChangeTerms !== undefined) {
            props.onChangeTerms(acceptedTerms.term1 && acceptedTerms.term2);
        }
    }, [acceptedTerms]);

    return (
        cartContext.cart ?
            <div className={cmpClass}>
                <div className={classMap.box}>
                    {
                        !props.hideTerms ?
                            <div className={classMap.terms}>
                                <UiCheckbox
                                    onChange={(value) => setAcceptedTerms({
                                        ...acceptedTerms,
                                        term1: value,
                                    })}
                                    label={
                                        <>
                                            {label.accept}
                                            {' '}
                                            <span
                                                onClick={(e) => handle.clickTerm1(e)}
                                                className={classMap.termsLink}
                                            >
                                                {label.terms1}
                                            </span>
                                        </>
                                    }
                                    defaultChecked={acceptedTerms.term1}
                                />
                                <UiCheckbox
                                    onChange={(value) => setAcceptedTerms({
                                        ...acceptedTerms,
                                        term2: value,
                                    })}
                                    label={
                                        <>
                                            {label.accept}
                                            {' '}
                                            <span
                                                onClick={(e) => handle.clickTerm2(e)}
                                                className={classMap.termsLink}
                                            >
                                                {label.terms2}
                                            </span>
                                        </>
                                    }
                                    defaultChecked={acceptedTerms.term2}
                                />
                            </div> :
                            <></>
                    }
                </div>
                <div className={classMap.box}>
                    {
                        !props.hidePrice ?
                            <BigTitle title={label.total} dark={true} className={classMap.title}/> :
                            <></>
                    }
                    {
                        !props.hidePrice ?
                            <div className={classMap.prices}>
                                <div className={classMap.price}>
                                    <span>{label.totalNoVat}</span>
                                    <p>
                                        {utils.formatPrice(cartContext.cart.total.noVat)} {label.currency}
                                    </p>
                                </div>
                                <div className={classMap.price}>
                                    <span>{label.totalVat}</span>
                                    <p>
                                        {utils.formatPrice(cartContext.cart.total.vat)} {label.currency}
                                    </p>
                                </div>
                                <div className={classMap.price}>
                                    <span>{label.totalWithVat}</span>
                                    <p>
                                        {utils.formatPrice(cartContext.cart.total.withVat)} {label.currency}
                                    </p>
                                </div>
                            </div> :
                            <></>
                    }

                    <div className={classMap.buttons}>
                        <UiButton
                            style={2}
                            text={props.buttons.backward.label}
                            onClick={() => props.buttons.backward.onClick()}
                        />
                        <Tooltip
                            title={props.blockForward ? props.buttons.forward.tooltipMessage : ''}
                            variant="soft"
                            color="danger"
                            placement="bottom-end"
                        >
                            <UiButton
                                style={1}
                                text={props.buttons.forward.label}
                                onClick={() => props.buttons.forward.onClick()}
                                disabled={props.blockForward}
                            />
                        </Tooltip>
                    </div>
                </div>
            </div> :
            <></>
    );
};

const cmpClass = 'cart-total';
const classMap = utils.generateClassMap(cmpClass, {
    box: 'box',
    terms: 'terms',
    termsLink: 'terms-link',
    title: 'title',
    prices: 'prices',
    price: 'price',
    buttons: 'buttons',
});
